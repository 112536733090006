<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
      <Preloader/>
    </div>
    <div v-else class="mt-4 mb-4">
      <div class="col-md-3">
        <label for="filterFilterDepartments">Фильтрация по кафедрам</label>
        <select class="form-control form-select" id="filterFilterDepartments" @input="changeDepartment">
          <option v-for="(item, index) in [{id: 0, name_ru: 'Выбрать кафедру'}, ...departments]" :value="item.id" :key="index"
                  :selected="item.id==departmentId">
            {{item.name_ru }}
          </option>
        </select>
      </div>

      <h4 class="text-center mb-2">Незаполненные силлабусы</h4>

      <DataTable :value="emptySyllabuses" :paginator="true" :rows="100"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[100,200,300]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <Column header="Преподаватель">
          <template #body="{data}">
            <span style="text-transform: capitalize">
              {{data?.lastname}} {{data?.firstname}}
            </span>
          </template>
        </Column>
        <Column field="name" header="Дисциплина"></Column>
        <Column field="semester" header="Семестер"></Column>
      </DataTable>

    </div>

  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  import httpClient from "../../services/http.service";
  import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: "SyllabusList",

    data() {
      return {
        emptySyllabuses: [],
        loading: true,
        departmentId: 0
      }
    },
    computed: {
      ...mapState('vacancy', ['departments']),
      getDepartment() {
        return getCookie('DEPARTMENT_ID')
      }
    },
    methods: {
      ...mapActions('vacancy', ['GET_DEPARTMENTS']),
      async getEmptySyllabus(departmentId) {
        try {
          const url = `syllabus_data/syllabus/get-empty-syllabus?access-token=${getCookie('ACCESS_TOKEN')}&department_id=${departmentId}`
          const {status, data} = await httpClient.get(url);
          if (status === 200) {
            this.emptySyllabuses = data
          }
        } catch (e) {
          console.error(e.response);
        }
      },
      async changeDepartment(e) {
        this.departmentId = e.target.value
        await this.getEmptySyllabus(this.departmentId)
      }

    },
    async mounted() {
      this.departmentId = this.getDepartment || 0
      await this.GET_DEPARTMENTS();
      await this.getEmptySyllabus(this.departmentId)
      this.loading = false
    }
  }
</script>

<style scoped>

</style>